
import { useState } from 'react';
import Translator from '../../../classes/Translator/Translator';
import { NftTableService } from './NftTable.module';

const NftTableLogic = (props = {}) => {
  const { translator } = Translator();
  const [loadingCsvItemId, setLoadingCsvItemId] = useState(null);
  const [loadingGhgItemId, setLoadingGhgItemId] = useState(null);
  const {
    drawerOpen,
    toggleDrawer,
    carbonFluxData,
    getCsv,
    getGhg,
  } = NftTableService(props);

  const handleCsvClick = async (stationData, createdAt, itemId, event) => {
    event.stopPropagation();
    setLoadingCsvItemId(itemId);
    await getCsv(stationData, createdAt);
    setLoadingCsvItemId(null);
  };

  const handleGhgClick = async (stationData, itemId, event) => {
    event.stopPropagation();
    setLoadingGhgItemId(itemId);
    await getGhg(stationData);
    setLoadingGhgItemId(null);
  };

  return {
    translator,
    loadingCsvItemId,
    loadingGhgItemId,
    drawerOpen,
    toggleDrawer,
    carbonFluxData,
    handleCsvClick,
    handleGhgClick,
  };
};

export default NftTableLogic;
