import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  useStyles,
  executiveChairman,
  ceo,
  cmo,
  cto,
  sustainabilityLeader,
  uxExpert,
  techLeader,
  businessDeveloperBoetsch,
  businessDeveloperLetelier,
} from './teamMembers.module';
import TeamMember from '../teamMember/teamMember';

const team = [
  {
    photo: executiveChairman,
    name: 'Gerhard Huber',
    role: 'Executive Chairman',
    linkedin: 'https://www.linkedin.com/in/gerhard-huber-14168a6/',
  },
  {
    photo: ceo,
    name: 'Andres Gonzalez',
    role: 'Co Founder & CEO',
    linkedin: 'https://www.linkedin.com/in/aigonza2014/',
  },
  {
    photo: cmo,
    name: 'Rodrigo Vega',
    role: 'Co Founder & CMO',
    linkedin: 'https://www.linkedin.com/in/rodrigo-vega-puyol-b2257567/',
  },
  {
    photo: cto,
    name: 'Jason Scherpenisse',
    role: 'Co Founder & CTO',
    linkedin:
      'https://www.linkedin.com/in/jason-sch-innovate-from-lab-to-market/',
  },
  {
    photo: sustainabilityLeader,
    name: 'Cynthia Guzman',
    role: 'Sustainability Leader',
    linkedin: 'https://www.linkedin.com/in/cynthiaguzmanramos/',
  },
  {
    photo: techLeader,
    name: 'Luis Conde',
    role: 'Tech development Leader',
    linkedin: 'https://www.linkedin.com/in/lconder/',
  },
  {
    photo: businessDeveloperBoetsch,
    name: 'Cristian Boetsch',
    role: 'Business Developer',
    linkedin: 'https://www.linkedin.com/in/cristian-boetsch-alamos-a6320951/',
  },
  {
    photo: businessDeveloperLetelier,
    name: 'Pablo Letelier Ovalle ',
    role: 'Business Developer',
    linkedin: 'https://www.linkedin.com/in/pablo-letelier-ovalle-54bb59b6/',
  },
];

const TeamMembers = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {team.map((data, index) => (
          <Grid className={classes.item} item xs={12} sm={6} md={4} lg={3}>
            <TeamMember
              photo={data.photo}
              role={data.role}
              name={data.name}
              href={data.linkedin}
              key={index}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TeamMembers;
