import { useState } from 'react';
import Storage from '../../classes/Storage/Storage';
import Translator from '../../classes/Translator/Translator';

const CarbonCreditsSearcherLogic = (getNfts) => {
  const { translator } = Translator();

  const [values, setValues] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (value) => {
    const isValidPattern = /^[a-zA-Z0-9]{10}$/.test(value);
    const isValidPublicKeyPatter = /^G[A-Z0-9]{55}$/.test(value);
    return isValidPattern || isValidPublicKeyPatter;
  };

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    setSearchValue(value);
    setIsButtonEnabled(validateInput(value));
  };

  const handleSubmit = async () => {
    try {
      setErrorMessage('');
      setValues(null);
      const nft = await getNfts(searchValue);

      if (nft) {
        setValues(nft);
      } else {
        setErrorMessage('La tabla esta vacía');
      }
    } catch (error) {
      if (error.status === 404) {
        // setErrorMessage('No se ha encontrado datos para este Crédito de Carbono');
        setErrorMessage(translator('carbonCredits.noDataFound'));
      } else {
        // setErrorMessage('Error realizando la petición');
        setErrorMessage(translator('carbonCredits.requestError'));
      }
    }
  };
  return {
    values,
    searchValue,
    handleInputChange,
    isButtonEnabled,
    handleSubmit,
    translator,
    errorMessage,
  };
};

export default CarbonCreditsSearcherLogic;
