import React from 'react';
import {
  Grid,
  Typography,
  Fab,
  CircularProgress,
  List,
} from '@material-ui/core';
import { useStyles } from './NftTable.module';

const DrawenNftTable = ({
  carbonFluxData,
  translator,
  handleCsvClick,
  handleGhgClick,
  loadingCsvItemId,
  loadingGhgItemId,
  csv,
  ftp,
  toggleDrawer,
}) => {
  const classes = useStyles();

  return (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={classes.rootDrawer}
    >
      <List>
        {carbonFluxData.map((item, index) => (
          <div key={item.id || index} className={classes.listDrawerOpen}>
            <div className={classes.marginTextCarbon}>
              <Grid container spacing={1}>
                {[
                  {
                    label: 'carbonCreditCard.datetime',
                    value: item.getDateFromStationData(),
                  },
                  {
                    label: 'carbonCreditCard.contribution',
                    value: item.getGrossContribution(),
                  },
                  { label: 'carbonCreditCard.flux', value: item.getFluxCO2() },
                  {
                    label: 'carbonCreditCard.productive',
                    value: item.getProductiveAreaSizeM2(),
                  },
                  {
                    label: 'carbonCreditCard.remainder',
                    value: item.getRemainder(),
                  },
                  {
                    label: 'carbonCreditCard.total',
                    value: item.getTotalCO2(),
                  },
                ].map(({ label, value }) => (
                  <Grid
                    key={label}
                    item
                    xs={12}
                    sm={2}
                    className={classes.gridCarbonCredit}
                  >
                    <Typography className={classes.headCarbonCredit}>
                      {translator(label)}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.textBreak}
                    >
                      {value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </div>

            <div
              className={classes.marginFileGhg}
              onClick={(event) => event.stopPropagation()}
            >
              <Grid container spacing={0}>
                {[
                  {
                    onClick: (event) =>
                      handleCsvClick(
                        item.station_data,
                        item.created_at,
                        index,
                        event
                      ),
                    icon: csv,
                    label: 'CSV',
                    loading: loadingCsvItemId === index,
                  },
                  {
                    onClick: (event) =>
                      handleGhgClick(item.station_data, index, event),
                    icon: ftp,
                    label: 'GHG',
                    loading: loadingGhgItemId === index,
                  },
                ].map(({ onClick, icon, label, loading }, idx) => (
                  <Grid key={idx} item xs={6} align="center">
                    <Fab
                      variant="extended"
                      color="primary"
                      size="large"
                      onClick={onClick}
                      className={classes.marginFile}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress
                          className={classes.circularProgressCustom}
                          size={24}
                        />
                      ) : (
                        <>
                          <img
                            src={icon}
                            alt={label.toLowerCase()}
                            className={classes.imgFile}
                          />
                          {label}
                        </>
                      )}
                    </Fab>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        ))}
      </List>
    </div>
  );
};

export default DrawenNftTable;
