const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        primary: {
          main: '#fff',
        },
        secondary: {
          main: '#F2F2F2',
        },
        tertiary: {
          main: '#006F56',
        },
        quaternary: {
          main: '#F2F2F2',
        },
        octonary: {
          main: '#F2F2F2',
        },
        nonary: {
          main: '#FFFFFF',
        },
        denary: {
          main: '#FFFFFF',
        },
        service: {
          main: '#FFFFFF',
        },
        borderSelect: {
          main: ' #041370',
          border:'#00DD9E',
          borderFocus:'#E0E0E0',
          borderDark:'#b1b1b1'

        },

        background: {
          default: '#fff',
          paper: '#fff',
          container: '#04679C',
          holder: '#80cbc4',
          vessel: '#fff',
          box: '#04679C',
          contain: '#fff',
          projectItem: 'rgba(247, 247, 247, 1)',
          selectProjects: '#F2F3F4',
          cardTeamMember: 'rgba(255, 255, 255, 0.5)',
          dark:'#ececec'

        },
        text: {
          primary: '#000',
          secondary: '#000000',
          tertiary: '#333333',
          quaternary: '#444444',
          quinary: '#00923D',
          senary: '#4db6ac',
          septenary: '#006F56',
          pay: '#fff',
          octonary: '#333333',
          nonary: '#041370',
          denary: '#000F20',
          service: '#FFFFFF',
          serviceText: '#04679C',
          selectProjects: '#77838F',
          role: '#555555',
          dark: '#333333',

        },
        button: {
          primary: '#fff',
          secondary: '#40ad6e',
          hover: '#40ad6e',
          border: '#40ad6e',
          text: '#40ad6e',
          pay: '#04679C',
          serviceButton: '#04679C',
          hoverService: '#04679C',


        },
        footer: {
          default: '#2c2c2c',
        },
        opinions: {
          primary: '#333333',
          secondary: '#333333',
        },
        search: {
          icon: '#666666',
          border: '#80B7AB',
          text: '#8C8C8C',
        },
        link: {
          default: '000F20',
        },
        input: {
          primary: '#000',
        },
      }
      : {
        // palette values for dark mode
        primary: {
          main: '#000',
        },
        secondary: {
          main: '#010C2B',
        },
        tertiary: {
          main: '#FFFFFF',
        },
        quaternary: {
          main: '#40ad6e',
        },
        borderSelect: {
          main: '#77838F',
          border:'transparent',
          borderFocus:'transparent',
          borderDark:'#00DD9E'
        },

        background: {
          default: '#041370;',
          // default: '#010C2B',
          paper: '#010C2B',
          container: '#58BDFD',
          holder: '#010C2B',
          vessel: '#58BDFD',
          box: '#0C1839',
          contain: '#1B2747',
          projectItem: '#1B2747',
          selectProjects: '#F2F3F4',
          cardTeamMember: '',
          dark:'rgba(0, 0, 0, 0.5) '


        },
        text: {
          primary: '#fff',
          secondary: '#fff',
          tertiary: '#fff',
          quaternary: '#fff',
          quinary: '#58BDFD',
          senary: '#58BDFD',
          septenary: '#58BDFD',
          nonary: '#fff',
          pay: '#04679C',
          denary: '#FFFFFF',
          service: '#06E89F',
          serviceText: '#06E89F',
          selectProjects: '#77838F',
          role: '#E8E8E8',
          dark:'#00DD9E'


        },
        button: {
          primary: '#58BDFD',
          secondary: '#010C2B',
          hover: '#daf1f9',
          border: '#58BDFD',
          text: '#58BDFD',
          pay: '#fff',
          serviceButton: '#06E89F',

        },
        footer: {
          default: '#010615',
        },
        opinions: {
          primary: '#58BDFD',
          secondary: '#afafaf',
        },
        search: {
          icon: '#FFFFFF',
          border: '#58BDFD',
          text: '#FFFFFF',
        },
        link: {
          default: 'FFFFFF',
        },
        input: {
          primary: '#000',
        },
      }),
  },
});

export default getDesignTokens;
