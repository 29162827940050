import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
  buttonNft:{
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'flex-end'
  },
  margin: {
    margin: theme.spacing(1),
    marginRight:21,
    marginBottom:21
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  containerNftTable:{
    height: '100vh'
  },
  containerItemNftTable:{
    flexGrow: 1 
   },
    containerFabNftTable:{
      alignItems: 'flex-end' 
    },
    rootOffset: {
      borderRadius: '20px',
      border: '4px solid #82c56b',
      borderTopColor: '#82c56b',
      borderBottomColor: '#80ca5a',
  },
  paper:{
    marginTop: '-8px', 
    borderRadius: '20px', 
    '@media screen and (min-width:576px)': {
      marginLeft: '-100px',
      width: '600px',

    },
    '@media screen and (max-width:992px)': {
      marginLeft: '0px',
      width: '100%',

    },
  },
 rootDrawer: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.3),
    },
  },
  listDrawerOpen: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  marginFile: {
    margin: theme.spacing(1),
  },
  marginFileGhg: {
    marginRight: theme.spacing(1),
  },
  marginTextCarbon: {
    width: '90%',
  },
  imgFile: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  imgFtp: {
    width: '25px',
    height: '25px',
    marginRight: '8px',
  },
  circularProgressCustom: {
    color: '#06E89F',
  },
  textBreak: {
    wordBreak: 'break-word',
    fontSize: '14.8px',
    fontFamily: 'Montserrat, sans-serif',
    [theme.breakpoints.up('md')]: {
      fontSize: '14.8px',
    },
    '@media screen and (max-width: 1024px)': {
      fontSize: '11.5px',
    },
  },
  gridCarbonCredit: {
    flexGrow: 1,
  },
  headCarbonCredit: {
    fontSize: '13.5px',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    fontFamily: 'Montserrat, sans-serif',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));
export default useStyles;
