import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';
import NFT from '../../classes/NFT/NFT';

const CarbonCreditsSearcherService = () => {
  const getNfts = async (id) => {
    const api = new API(`${URL.URL_SERVER}/nfts/${id}`);
  
    try {
      const http_response = await api.get();
      const has_error = http_response instanceof CustomError;
      if (has_error) {
        // eslint-disable-next-line no-throw-literal
        throw { status: http_response.status, message: http_response.error_data };
      }else {
        const response = new Response(http_response, NFT);
        return response.wasSuccessfull().single();
      }
    } catch (error) {
      const status = error.response?.status || error.status || 500;
      const message = error.response
        ? 'error en la respuesta del servidor'
        : error.request
        ? 'error de la red, verifica tu conexión'
        : error.message || 'error en el servicio';
  
      // eslint-disable-next-line no-throw-literal
      throw { status, message };
    }
  };
  
      return {
        getNfts    
      };
}

export default CarbonCreditsSearcherService;
